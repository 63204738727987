<template>
  <div class="page" id="auth-leave-page">
    <div class="container" ref="container">
      <div class="page-header">
        <h1 class="title">
          {{ $__t("회원 탈퇴") }}
        </h1>
      </div>

      <div class="page-body">
        <div class="section">
          <h2> {{ $__t("럭스테이를 떠나시나요?") }} </h2>
          <div class="secondary">
            <p>{{ $__t("더 나은 서비스를 위해 탈퇴 사유를 알려주세요.") }}</p>
          </div>
        </div>

        <div class="section">
          <ul class="options">
            <li class="option" v-for="(item, i) in items" :key="i">
              <label class="label" :for="item.value">
                <input type="checkbox" v-model="selectedReasons[item.value]" :id="item.value" :value="item.value" />
                <i class="fas fa-check"></i>

                {{ item.label }}
              </label>
              <input v-if="item.detail !== undefined" v-model.trim="item.detail" :placeholder="item.placeholder" type="text" :disabled="!selectedReasons['etc']" @focus="selectedReason = i; item.error = '';" />
              <div v-if="item.error" class="error"> {{ item.error }} </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="page-footer">
        <div class="actions">
          <button type="button" v-button class="close" @click="$router.back()">
            <span>{{ $__t("닫기") }}</span>
          </button>
          
          <button ref="submit" v-button type="button" class="submit" :class="{ disabled: !submittable }" :disabled="!submittable" @click="onClickkSubmit" >
            <loader v-if="sending" type="material" :size="0.5" />
            <span v-else>{{ $__t("탈퇴하기") }}</span>
          </button>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/v3/components/common/loader/Loader.vue';

export default {
    data() {
        return {
            sending: false,
            reasons: {
                "no_luggage_spaces": { label: "보관할 장소가 없어요", value: "no_luggage_spaces" },
                "has_complaints_from_host": { label: "호스트 대응 과정에 불만이 있어요", value: "has_complaints_from_host" },
                "high_price": { label: "가격이 비싸요", value: "high_price" },
                "bugs": { label: "오류 및 버그로 사용이 불편해요", value: "bugs" },
                "delete_personal_infos": { label: "개인 정보를 삭제하고 싶어요", value: "delete_personal_infos" },
                "etc": { label: "기타", value: "etc", placeholder: "탈퇴 사유를 작성해주세요.", detail: "", error: "",
                },
            },
            selectedReasons: {},
        };
    },
    computed: {
        items() {
            return _.toArray(this.$data.reasons);
        },
        selectedItems() {
            const selectedItems = [];
            _.each(this.selectedReasons, (value, key) => {
                if (value === true) {
                    selectedItems.push(key);
                    if (key === "etc" && this.$data.reasons.etc.detail) {
                        selectedItems.push(this.$data.reasons.etc.detail);
                    }
                }
            });
            return selectedItems;
        },
        submittable() {
            return this.selectedItems.length > 0;
        }
    },
    methods: {
        onClickkSubmit(e) {
            this.submit();
        },
        async submit() {
            try {
                if (this.$data.sending) {
                  return;
                }

                this.$data.sending = true;

                const reasons = this.selectedItems.join(",");
                await this.axios.post(this.$store.state.config.apiURL + "/v2/user/unregister", {
                    reasons
                }, { headers: this.$store.state.config.userHeaders });
                this.$store.dispatch("auth/logout");
                this.$store.commit("SET_TRUE", { key: "appIntroViewed" });
            }
            catch (e) {
                this.$store.commit("alert/ADD_ITEM", { message: this.$__t(e), status: "error" });
            }
            finally {
                this.$data.sending = false;
            }
        }
    },
    components: { Loader }
}
</script>

<style lang="scss" scoped>
#auth-leave-page {
  @include container('& > .container', $max-content-width);

  background-color: $color-white;
  min-height: calc(100vh - 6rem);

  .page-header {
    padding: unit(20);

    .title {
      font-size: unit(22);
      font-weight: 500;
    }
  }

  .page-body {
    padding: unit(20);
    padding-bottom: unit(100);

    .section {
      h2 {
        font-size: unit(24);
        font-weight: 600;
        color: #101010;
      }

      .secondary {
        p {
          font-size: unit(16);
          font-weight: 500;
          color: #787878;
        }
      }

      .options {
        margin-top: unit(16);

        .option {
          & + .option {
            margin-top: unit(16);
          }

          display: flex;
          align-items: center;
          flex-wrap: nowrap;

          label {
            position: relative;
            font-size: unit(14);
            line-height: unit(20);
            color: $color-gray-9;

            input[type='checkbox'] {
              width: unit(21);
              height: unit(21);
              display: inline-block;
              background-color: $color-white;
              vertical-align: text-bottom;
              line-height: unit(21);
              border-radius: unit(4);
              border: 1px solid #ced3d6;
              margin-right: unit(4);

              &:checked {
                // background-color: #008fff;
                background-color: #48D9EB;
                border: 1px solid transparent;

                & + i {
                  display: block;
                }
              }

              transition: all 0.2s;
            }

            i.fas.fa-check {
              display: none;
              position: absolute;
              left: calc(1.667rem / 2);
              top: unit(3.5);
              transform: translateX(-50%);
              font-size: 1.25 * $unit;
              font-weight: 600;
              color: $color-white;
              // -webkit-text-stroke: unit(1.8) #008fff;
              -webkit-text-stroke: unit(1.8) #48D9EB;
            }
          }

          input[type='text'] {
            font-size: unit(14);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #151515;
            border-radius: 0;
            border-bottom: solid 1px #c2c2c2;
            transition: all 0.2s;
            flex: 1;
            line-height: unit(20);
            margin-left: unit(16);

            &:focus {
              // border-bottom: solid 1px #008fff;
              border-bottom: solid 1px #48D9EB;
            }

            &::placeholder {
              color: #a9afb3;
            }

            &:disabled {
              color: #c2c2c2 !important;
            }
          }
        }

        .error {
          padding-left: 0.5 * $unit;
          font-weight: 0.917 * $unit;
          text-align: right;
          line-height: 1.43;
          color: $color-red;
        }
      }
    }
  }

  .page-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: $max-content-width;
    left: 50%;
    transform: translateX(-50%);

    .actions {
      display: flex;

      button {
        display: flex;
        align-items: center;
        justify-content: center;

        > span {
          color: $color-white;
          padding: unit(20);

          text-transform: uppercase;
          font-size: unit(15);
          line-height: unit(22);
          text-align: center;
        }

        &.close {
          flex: 1;
          background: #ced3d6;
        }

        &.submit {
          flex: 2.2;
          background-color: #48D9EB;
          cursor: pointer;
        }

        &.disabled {
          background: #a9afb3;
          pointer-events: none;
        }
      }
    }
  }
}
</style>